/**
 * Slouží k práci s JSON configy ve formě <script type="application/json" id="configId">{"JSON": true}</script>
 */
class Config
{
    /**
     * Lazyloadingem vrátí konfiguraci podle id na elementu application/json
     * 
     * @param  {string} configId id na elementu application/json
     * @return {Object} konfigurace
     */
    static get(configId) {
       /**
        * Pole s jednotlivými konfiguracemi
        * @type {Array}
        */
        if (!this.configurations) {
            this.configurations = [];
        }
        
        if (!this.configurations[configId]) {
            var element = document.getElementById(configId);

            if (!element) {
                return null;
            }

            this.configurations[configId] = JSON.parse(element.text);
        }

        return this.configurations[configId];
    } 
}

export default Config